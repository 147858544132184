import { styled, TextField, Slider } from "@mui/material";
import React, { useState, useEffect, useCallback, Component } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';

import Image1 from "assets/images/slot/HCC.png"
import Image2 from "assets/images/slot/HDC.png"
import Image3 from "assets/images/slot/HTT.png"
import Image4 from "assets/images/slot/work.png"
import Image5 from "assets/images/slot/blue_crate.png"
import Image6 from "assets/images/slot/Riley_shiny.png"
import Image7 from "assets/images/slot/red_crate.png"
import Image8 from "assets/images/slot/trickster_pack.png"
import Image9 from "assets/images/slot/turan_shiny.png"
import Image10 from "assets/images/slot/safe_mega.png"
import Image11 from "assets/images/slot/production.png"
import Image12 from "assets/images/slot/safe_pack.png"
import Image13 from "assets/images/slot/get_out_of_jail.png"
import Image14 from "assets/images/slot/trickster_logo.png"
import Image15 from "assets/images/slot/beginner_pack.png"
import Image16 from "assets/images/slot/trickster_crate.png"



import { getLastActionMessage, refreshAll, gambleLucky, anchorGetGRank, gambleWaxBlockchain, gambleWUffBlockchain, getJackpot } from "BlockchainInteractionWax";

import { FaInfoCircle, FaPlus } from "react-icons/fa";
import ProfileForm from 'components/Profile/profileForm';
import Safehouse from "assets/images/safehouse.jpg";
import SlotMachine from 'assets/images/SlotMachine3.png';
import { useError } from 'ErrorProvider';

const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12, Image13, Image14, Image15, Image16];

const Gambling = ({ ual }) => {
  const [gRank, setGRank] = useState(0);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [sliderValue, setSliderValue] = useState(1); // State for slider value
  const [highlightedRow, setHighlightedRow] = useState(0); // State for highlighted row
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State for the current image index
  const [waxTokens, setWaxTokens] = useState("")
  const [wuffTokens, setWuffTokens] = useState("")

  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setError } = useError();

  const fetchData = useCallback(async () => {
    if (ual.activeUser) {
        const value = await anchorGetGRank(ual);
        setGRank(parseFloat(value).toFixed(1));

        const value2 = await getJackpot(ual)

        setWaxTokens(value2[0]);
        setWuffTokens(parseInt(value2[1]).toLocaleString('de-DE').replace(/\./g, ' '));

        const value7 = await getLastActionMessage(ual);
        if (value7 !== popUpMessage && popUpMessage !== "") {
          setPopUpMessage(value7)
          setIsPopupOpen(true);
        }
        else {
          setPopUpMessage(value7)
        }
    }
  }, [ual, store]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function formatNumber(number) {
    return new Intl.NumberFormat('de-DE', {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(number).replace(/\./g, ' ').replace(/,/g, ',');
}

  async function handleGetReport()
  {
    const value7 = await getLastActionMessage(ual);
    setPopUpMessage(value7)
    setIsPopupOpen(true);
  }

  async function gambleWax()
  {
    await gambleWaxBlockchain(ual, setError)
    refreshAll(dispatch)
  }

  async function gambleWuf()
  {
    await gambleWUffBlockchain(ual, setError)
    refreshAll(dispatch);
  }


  async function gambleAway() {
    await gambleLucky(ual, sliderValue, setError)
    refreshAll(dispatch)
  }

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 500); // Change image every second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div style={{ marginTop: "100px" }}> {/* Add a top margin */}
      <img src={Safehouse} style={{ position: 'fixed', top: 0, left: 0, width: "100%", height: "100vh", opacity: '0.3', zIndex: "0" }} />
  
      <div className="mx-2 mr-2 mb-5 rounded-md py-8 px-5 text-lg border border-[#252525] text-white text-center justify-center items-center" style={{ backgroundSize: "cover", position: "relative" }}>
        <div style={{ position: 'relative', width: "35%", height: "35%", margin: "auto" }}>
          <img src={SlotMachine} style={{ width: "100%", height: "100%", opacity: '1', zIndex: "1" }} />
          <div style={{ position: 'absolute', top: '35%', left: '30%', width: '40%', height: '27%', overflow: 'visible' }}>
            <img src={images[currentImageIndex]} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          </div>
        </div>
        <div className="flex flex-row mb-4">
  <div className="flex flex-col w-1/3"></div>
  <div className="flex flex-row w-1/3 my-5 space-x-4 items-center">
    <button
      onClick={gambleWax}
      className="w-full py-2 px-4"
      style={{
        color: "#00ff00",
        background: "linear-gradient(45deg, #007BFF, #FF4136)",
        fontSize: "16px",
        fontWeight: "bold",
        border: "none",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 123, 255, 0.6)",
        transition: "transform 0.3s ease-in-out",
      }}
    >
      WAX
    </button>    
    {/* <button
      onClick={gambleWuf}
      className="w-full py-2 px-4"
      style={{
        color: "#00ff00",
        background: "linear-gradient(45deg, #007BFF, #FF4136)",
        fontSize: "16px",
        fontWeight: "bold",
        border: "none",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 123, 255, 0.6)",
        transition: "transform 0.3s ease-in-out",
      }}
    >
      WUF
    </button> */}
    <div className="flex flex-col flex-1 justify-center pl-5">
      <div className="w-full flex flex-col items-start mb-2 relative">
        <TooltipForGambling />
      </div>
    </div>
  </div>
</div>
<p style={{ fontWeight: 'bold', fontSize: '1.5em', color: 'white' }}>
  WAX JACKPOT:  {Number(waxTokens).toFixed(2)}
</p>
{/* <p style={{ fontWeight: 'bold', fontSize: '1.5em', color: 'white' }}>
  WUF JACKPOT: {wuffTokens}
</p> */}
        {gRank > 25 && (
          <div className="flex flex-row mb-4">
            <div className="flex flex-col w-1/3 "></div>
            <div className="buttonContainer w-1/3 my-5">
              <button 
                className="w-full py-2 px-4" 
                onClick={() => gambleAway()} 
                style={{
                  color: "#00ff00", 
                  background: "linear-gradient(45deg, #007BFF, #FF4136)",  
                  fontSize: "16px", 
                  fontWeight: "bold", 
                  border: "none",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 123, 255, 0.6)",  
                  transition: "transform 0.3s ease-in-out"
                }}
                onMouseOver={() => this.style.transform = "scale(1.1)"}
                onMouseOut={() => this.style.transform = "scale(1)"}
              >
                The Trickster
              </button>
              <Slider
                value={sliderValue}
                onChange={handleSliderChange}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="off"
                step={1}
                marks
                min={0}
                max={10}
                style={{ width: '80%' }}
              />
              <p>Times using the trickster: {sliderValue}</p>
            </div>
            <div className="flex flex-col flex-1 justify-center pl-5">
              <div className="w-full flex flex-col items-start mb-2 relative">
                <TooltipForLuckyInfo />
              </div>
            </div>
          </div>
        )}
      </div>
  
      {isPopupOpen && (
        <div className="flex justify-center items-center fixed inset-0 z-50">
          <div className="flex flex-col w-1/3 items-center bg-[#1a1b1f] border border-solid justify-center border-gray-500 rounded-xl py-4 px-6"
            style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}>
            <p className="text-white text-md font-semibold mb-4" style={{ textAlign: 'center' }}>
              {popUpMessage}
            </p>
            <div className='buttonContainer'>
              <button className="w-full px-4 py-1" onClick={() => setIsPopupOpen(false)}>Ok</button>
            </div>
          </div>
        </div>
      )}
  
      <div className="mt-5 flex items-center justify-center">
        <div className="flex flex-col w-1/3"></div>
        <div className="buttonContainer w-1/3 my-5">
          <div className="w-full">
            <button className="w-full px-4 py-1" onClick={handleGetReport}>Get Report</button>
          </div>
        </div>
        <div className="flex flex-col w-1/3"></div>
      </div>
    </div>
  );
};

class TooltipForLuckyInfo extends Component {
    render() {
      return (
        <Tippy
          content={<span className="">Play 0.0085 HTT for a chance to win a Hustle Ingredient! You need to have a minimum of 2 HDC in your in game wallet to use the Trickster!</span>}
          popperOptions={{
            placement: "right",
          }}
        >
          <p className="text-xl text-white">
            <FaInfoCircle />
          </p>
        </Tippy>
      );
    }
  }

  class TooltipForGambling extends Component {
    render() {
      return (
        <Tippy
          content={<span className="">Play 5 WAX or 400000 WUFFI for a chance to WIN!!! 15% of each spin goes into the Jackpot and 5% into the season rewards!</span>}
          popperOptions={{
            placement: "right",
          }}
        >
          <p className="text-xl text-white">
            <FaInfoCircle />
          </p>
        </Tippy>
      );
    }
  }

const MyTextField = styled(TextField)({
  border: '1px #787878 solid',
  borderRadius: '5px',
  input: {
    color: '#fff'
  }
})

export default Gambling;